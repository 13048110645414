import { mapState } from 'vuex';
import { inputTypes, dropdownTypes } from '@om/editor-ssr-shared/src/utils';

export default {
  computed: {
    ...mapState(['selectedElement']),

    selectedElementType() {
      return this.selectedElement.data.form.customSettings.type;
    },
    isInputType() {
      return this.inputTypes.includes(this.selectedElementType);
    },
    inputTypes() {
      if (this.selectedElement.type === 'OmDropdown') {
        return dropdownTypes;
      }

      return inputTypes;
    },
  },
};
