<template lang="pug">
.form-modal(
  v-if="showFormManager.secondStep"
  @mousedown="changeFormManagerVisibility({ hide: 'secondStep' })"
)
  .content(@mousedown.stop="")
    .header.om-bottom-divider.p-3
      .header-inner.w-100
        .col-12.header-content
          .d-flex.align-items-center
            .mb-0.font-weight-bold(style="font-size: 18px") {{ $t('newField') }}
            om-button.ml-auto.p-1(
              icon="times"
              iconSize="1.715em"
              ghost
              iconOnly
              style="color: #b9bec6"
              @click="changeFormManagerVisibility({ hide: 'secondStep' })"
            )
    .d-flex.px-3.py-2.flex-column
      .row.py-2.px-4
        .types.col.header-content
          span {{ $t('name') }}
            input.simpleInput(:placeholder="$t('valuePlaceholder')" v-model="selectedFormName")
          span.alt-title.mt-1 {{ $t('customFieldNewTooltip') }}
      .row.py-2.px-4(v-if="isExcluded")
        .types.col.header-content
          span {{ $t('type') }}
            .sidebar-input-wrapper.flex-row-baseline
              span.epic-simple-input
                .select-arrow
                  i.fa.fa-angle-up(aria-hidden="true")
                select(v-model="selectedFormType")
                  template(v-for="item in inputTypes")
                    option(:value="item") {{ $t(item) }}
      .row.pt-2.px-4.footer.mt-3
        .col-7.flex-grow(style="width: 50%")
          .alt-title {{ $t('inputModifyWarning') }}
        .col.flex-row.justify-content-end
          om-button(ghost @click="changeFormManagerVisibility({ hide: 'secondStep' })") {{ $t('cancel') }}
          om-button.ml-3(
            primary
            :disabled="isNameEmpty || loading"
            @click="createNewCustomField"
            :loading="loading"
          ) {{ $t('save') }}
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import { gqlClient as axios } from '@/editor/axios';
  import { addInputDefaultOptions } from '@/editor/util';
  import { nanoid } from 'nanoid';
  import formChooser from '@/editor/mixins/formChooser';
  import itemMixin from '../../../mixins/item';

  export default {
    mixins: [formChooser, itemMixin],

    data() {
      return {
        loading: false,
        selectedFormName: '',
        selectedFormType: 'email',
      };
    },

    computed: {
      ...mapState(['inputs', 'templateSaveData', 'showFormManager', 'selectedElement']),
      isNameEmpty() {
        return this.selectedFormName.length === 0;
      },
    },

    watch: {
      'showFormManager.secondStep': function (v) {
        if (v) {
          this.selectedFormName = '';
          this.selectedFormType = this.inputTypes[0];
        }
      },
    },

    mounted() {
      if (this.showFormManager.secondStep) {
        this.selectedFormName = '';
        this.selectedFormType = this.inputTypes[0];
      }
    },

    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      createNewCustomField() {
        if (this.selectedFormName.length !== 0) {
          this.loading = true;
          const { type, value } = this.templateSaveData;
          const uniqueId = nanoid(9);
          this.selectedFormType = this.isInputType
            ? this.selectedFormType
            : this.selectedElementType;
          const fieldInfo = {
            name: this.selectedFormName,
            type: this.selectedFormType,
            customId: uniqueId,
          };

          const templateQuery = `mutation{newMasterField(input: {name: "${fieldInfo.name}", type: "${fieldInfo.type}", customId: "${fieldInfo.customId}", template: "${value}"})}`;
          const variantQuery = `mutation{newField(input: {name: "${fieldInfo.name}", type: "${fieldInfo.type}", customId: "${fieldInfo.customId}"})}`;
          axios.gqlPost(type === 'base' ? templateQuery : variantQuery).then(() => {
            const customField = addInputDefaultOptions([fieldInfo], this.selectedElement)[0];
            this.changeFormManagerVisibility({ hide: 'secondStep' });
            this.$bus.$emit('switchInputElement', { customField });
          });
          this.loading = false;
        }
      },
    },
  };
</script>
<style lang="sass" scoped>
  .content
    min-height: auto !important
  .types
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 12px
    color: #505763
  .alt-title
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 16px
    color: #8F97A4
  .button-link
    color: black !important
  .custom
    font-family: Roboto
    font-style: normal
    font-weight: bold
</style>
